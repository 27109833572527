import * as React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"

export default function Blog({ data }) {
	return (
		<Layout>
			<main>
				{data.allWpPost.nodes.map(el => (
					<article
						key={el.title}
						dangerouslySetInnerHTML={{
							__html: `
							${el.content}
							<address rel="author">${el.author.node.name}</address>
							<hr />`
						}}>
					</article>
				))}
			</main>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		allWpPost(sort: { fields: [date] }) {
			nodes {
				title
				content
				author {
					node {
						name
					}
				}
			}
		}
	}
`